export let QuestionAns=[
    {
        id : '1',
        Question : "Why do we use it ?",
        Ans : "We use IT to facilitate efficient processes, enhance communication, store and analyze data, foster innovation, and address technological challenges. IT plays a crucial role in optimizing operations and driving success across various industries in today's interconnected world."
    },
    {
        id : '2',
        Question : "Who we use it ?",
        Ans : "We use IT to innovate, communicate, store data securely, and streamline operations. From businesses optimizing processes to individuals connecting globally, IT empowers us to navigate and thrive in the digital age."
    },
    {
        id : '3',
        Question : "can we use it ?",
        Ans : "Yes, we can use IT to improve efficiency, communication, and innovation. From businesses to individuals, IT offers solutions for diverse needs, empowering us to leverage technology for success and advancement in various aspects of life and work."
    },
    {
        id : '4',
        Question : "What Three Solutions are ?",
        Ans : "Three solutions could include implementing software systems, providing technical support services, and offering consulting expertise to address clients IT needs effectively."
    },
    {
        id : '5',
        Question : "Do or Not ?",
        Ans : "The decision to proceed depends on various factors such as feasibility, cost-effectiveness, and alignment with objectives. A tho"
    },
    
]

export default QuestionAns;