// auth.js
import { createSlice } from "@reduxjs/toolkit";

export const getUserRole = () => {
  // Replace this with actual logic to get user role from authentication state or storage
  return localStorage.getItem('role'); // Example: "admin" or "user"
};

const initialAuthState = {
  isLoggedIn: localStorage.getItem("token") ? true : false,
  id: localStorage.getItem("id") || "",
  role: localStorage.getItem("role") || "user",
  fullName: localStorage.getItem("fullName") || "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false; // Correctly update the isLoggedIn state
      state.role = "user"; // Correctly update the role state
      localStorage.clear("token");
      localStorage.clear("role");
      localStorage.clear("fullName");
      localStorage.clear("id");
    },
    changeRole: (state, action) => {
      const role = action.payload;
      state.role = role;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
