import axios from "axios";

export const API_BASE_URL = "https://webalphatechnosoftbackend-7.onrender.com/";  
// export const API_BASE_URL = "http://localhost:5001/";   

const jwt = localStorage.getItem("token")

export const api = axios.create({
    baseURL : API_BASE_URL,
    headers:{
        "Authorization":`Bearer ${jwt}`,
        'Content-Type': "application/json",
    },
})
