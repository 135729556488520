export let BrandImageData=[
    

    {
        id:'1',
        image:'./images/1.png'
    },
    {
        id:'2',
        image:'./images/2.png'
    },
    {
        id:'3',
        image:'./images/3.png'
    },
    {
        id:'4',
        image:'./images/4.png'
    },
    {
        id:'5',
        image:'./images/5.png'
    },
    {
        id:'6',
        image:'./images/6.png'
    },
    {
        id:'7',
        image:'./images/7.png'
    },
    {
        id:'8',
        image:'./images/8.png'
    },
    {
        id:'9',
        image:'./images/9.png'
    },
    {
        id:'10',
        image:'./images/10.png'
    },
    {
        id:'11',
        image:'./images/11.png'
    },
    {
        id:'12',
        image:'./images/12.png'
    },
    {
        id:'13',
        image:'./images/13.png'
    },
]

export default BrandImageData;
