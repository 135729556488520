export let SomethingNewData =[

    {
        id:'1',
        Title:'SEO',
        image:'/images/something-new-img1.jpeg',
        Description:'Our experts ensure that your website ticks all the boxes for constructive search engine optimization. We ensure high visibility with a rich website'
    },
    {
        id:'1',
        Title:'MEO',
        image:'/images/something-else-img.jpeg',
        Description:'Our experts ensure that your website ticks all the boxes for constructive search engine optimization. We ensure high visibility with a rich website'
    },
    
]


export default SomethingNewData;