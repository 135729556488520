import React from 'react'

const Error = () => {
  return (
    <div>
        <h1>This is a Error page</h1>
    </div>
  )
}

export default Error