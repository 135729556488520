export let TestomonialData = [

    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial1.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },
    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial2.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },
    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial3.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },
    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial4.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },
    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial6.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },
    {
        id:'1',
        Name:"Rohan Singh",
        Images:'./images/testomonial2.jpg',
        Message:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptate enim est quisquam corrupti et inventore repellat quod, harum id cumque repudiandae perferendis doloremque a libero ut exercitationem! Nam deserunt natus facilis libero animi!'
    },

]

export default TestomonialData;